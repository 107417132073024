<template>
  <div v-if="foods && foods.length">
    <v-data-table
      :headers="headers"
      :items="foods"
      item-key="id"
      :single-expand="true"
      :expanded.sync="expanded"
      :items-per-page="-1"
      class="fixed-table"
      hide-default-footer
    >
      <template v-slot:item="{ item, expand, isExpanded }">
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div
                class="svg-icon svg-icon-sm d-flex align-items-center success-color"
              >
                <v-btn @click.stop="expand(!isExpanded)" icon>
                  <v-icon
                    >mdi-{{
                      isExpanded ? 'chevron-down' : 'chevron-up'
                    }}</v-icon
                  >
                </v-btn>
              </div>
              <div>
                <avatar
                  size="30px"
                  :text="item.foodName"
                  :src="item.image.url"
                  :rounded="true"
                  class="mr-3"
                />
                <span>{{ item.foodName }}</span>
              </div>
            </div>
          </td>
          <td class="text-center">
            {{ roundNumber(item.portion) }} {{ item.foodUnitName }}
          </td>
          <td class="text-center">{{ roundNumber(item.calorie) }}</td>
          <td class="text-center">{{ roundNumber(item.glucose) }}</td>
          <td class="text-center">{{ roundNumber(item.protein) }}</td>
          <td class="text-center">{{ roundNumber(item.lipid) }}</td>
          <td class="text-center">{{ roundNumber(item.fibre) }}</td>
          <td>{{ item.note }}</td>
          <td v-if="!isDetail" class="text-center">
            <span>
              <action-dropdown
                :value="item"
                :show_delete="false"
                :show_copy="false"
                :show_view="false"
                :show_create="true"
                :dropup="true"
                :loading="loading"
                boundary="window"
                @edit="editFood(item)"
              >
                <template>
                  <b-dropdown-text
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="addFoodReplace(item)"
                  >
                    <a class="navi-link cursor-pointer">
                      <span class="menu-icon svg-icon svg-icon-sm text-primary">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/plus.svg"
                        />
                      </span>
                      <span class="success navi-text text-primary">
                        Thêm món ăn thay thế
                      </span>
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-divider v-if="showBtnDelete" />
                </template>
                <b-dropdown-text
                  v-if="showBtnDelete"
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="showPopupDelete(item)"
                >
                  <a class="navi-link cursor-pointer">
                    <span class="menu-icon svg-icon svg-icon-sm text-danger">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/trash.svg"
                      />
                    </span>
                    <span class="success navi-text text-danger"> Xóa </span>
                  </a>
                </b-dropdown-text>
              </action-dropdown>
            </span>
          </td>
        </tr>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="p-0">
          <v-data-table
            :headers="headers"
            :items="item.foodSubstitute"
            hide-default-footer
            hide-default-header
            :items-per-page="-1"
            class="fixed-table"
            no-data-text="Không có món ăn thay thế cho món chính"
          >
            <template v-slot:item="{ item: foodSubstitute }">
              <tr
                style="border-bottom: 1px solid #ccc; overflow: auto"
                class="position-static"
              >
                <td style="width: 25%">
                  <div class="d-flex align-items-center">
                    <div
                      class="svg-icon svg-icon-sm d-flex align-items-center success-color div-blank"
                    ></div>
                    <div>
                      <avatar
                        size="30px"
                        :text="foodSubstitute.foodName"
                        :src="foodSubstitute.image.url"
                        :rounded="true"
                        class="mr-3"
                      />
                      <span>{{ foodSubstitute.foodName }}</span>
                    </div>
                  </div>
                </td>
                <td style="width: 10%" class="text-center">
                  {{ roundNumber(foodSubstitute.portion) }}
                  {{ foodSubstitute.foodUnitName }}
                </td>
                <td style="width: 5%" class="text-center">
                  {{ roundNumber(foodSubstitute.calorie) }}
                </td>
                <td style="width: 5%" class="text-center">
                  {{ roundNumber(foodSubstitute.glucose) }}
                </td>
                <td style="width: 5%" class="text-center">
                  {{ roundNumber(foodSubstitute.protein) }}
                </td>
                <td style="width: 5%" class="text-center">
                  {{ roundNumber(foodSubstitute.lipid) }}
                </td>
                <td style="width: 5%" class="text-center">
                  {{ roundNumber(foodSubstitute.fibre) }}
                </td>
                <td style="width: 20%">{{ foodSubstitute.note }}</td>
                <td v-if="!isDetail" style="width: 5%" class="text-center">
                  <span>
                    <action-dropdown
                      :value="item"
                      :show_delete="false"
                      :show_copy="false"
                      :show_view="false"
                      :dropup="true"
                      :loading="loading"
                      boundary="window"
                      @edit="editFoodReplace(foodSubstitute, item.id)"
                    >
                      <b-dropdown-text
                        tag="div"
                        class="navi-item cursor-pointer"
                        @click="showPopupDelete(foodSubstitute)"
                      >
                        <a class="navi-link cursor-pointer">
                          <span
                            class="menu-icon svg-icon svg-icon-sm text-danger"
                          >
                            <inline-svg
                              class="svg-icon"
                              src="/media/svg/icons/Neolex/Basic/trash.svg"
                            />
                          </span>
                          <span class="success navi-text text-danger">
                            Xóa
                          </span>
                        </a>
                      </b-dropdown-text>
                    </action-dropdown>
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { SET_DELETE_FOOD } from '@/core/services/store/menu.module';
const { mapMutations, mapState } = createNamespacedHelpers('menu');
export default {
  name: 'TableMenu',
  props: {
    foods: {
      type: Array,
      default: () => [],
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: [],
      loading: false,
    };
  },
  computed: {
    ...mapState(['menuDetail', 'deleteFoodSuccess']),
    headers() {
      if (!this.isDetail) {
        return [
          {
            text: 'Món ăn',
            align: 'left',
            value: 'foodName',
            sortable: false,
            width: '25%',
          },
          {
            text: 'Khẩu phần',
            value: 'portion',
            sortable: false,
            align: 'center',
            width: '10%',
          },
          {
            text: 'Kcal',
            value: 'calorie',
            sortable: false,
            align: 'center',
            width: '5%',
          },
          {
            text: 'Đường',
            value: 'glucose',
            sortable: false,
            align: 'center',
            width: '5%',
          },
          {
            text: 'Đạm',
            value: 'protein',
            sortable: false,
            align: 'center',
            width: '5%',
          },
          {
            text: 'Béo',
            value: 'lipid',
            sortable: false,
            align: 'center',
            width: '5%',
          },
          {
            text: 'Xơ',
            value: 'fibre',
            sortable: false,
            align: 'center',
            width: '5%',
          },
          {
            text: 'Chú thích',
            value: 'note',
            sortable: false,
            align: 'left',
            width: '20%',
          },
          {
            text: '',
            value: 'action',
            sortable: false,
            align: 'center',
            width: '5%',
          },
        ];
      }
      return [
        {
          text: 'Món ăn',
          align: 'left',
          value: 'foodName',
          sortable: false,
          width: '25%',
        },
        {
          text: 'Khẩu phần',
          value: 'portion',
          sortable: false,
          align: 'center',
          width: '10%',
        },
        {
          text: 'Kcal',
          value: 'calorie',
          sortable: false,
          align: 'center',
          width: '5%',
        },
        {
          text: 'Đường',
          value: 'glucose',
          sortable: false,
          align: 'center',
          width: '5%',
        },
        {
          text: 'Đạm',
          value: 'protein',
          sortable: false,
          align: 'center',
          width: '5%',
        },
        {
          text: 'Béo',
          value: 'lipid',
          sortable: false,
          align: 'center',
          width: '5%',
        },
        {
          text: 'Xơ',
          value: 'fibre',
          sortable: false,
          align: 'center',
          width: '5%',
        },
        {
          text: 'Chú thích',
          value: 'note',
          sortable: false,
          align: 'left',
          width: '20%',
        },
      ];
    },
    showBtnAddFood() {
      if (this.menuDetail?.allowSugguestionMenu) return true;
      return false;
    },
    showBtnDelete() {
      return this.foods.length > 1;
    },
  },
  methods: {
    ...mapMutations({ SET_DELETE_FOOD }),
    async deleteFood(food) {
      this.loading = true;
      try {
        const { meta } = await this.$api.delete(
          `/FoodTemplate/Input/${food.id}`,
        );
        if (!meta.success) return;

        this.SET_DELETE_FOOD(!this.deleteFoodSuccess);
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    editFood(food) {
      this.$emit('edit', food.id);
    },
    editFoodReplace(food, parentId) {
      this.$emit('edit-replace', { id: food.id, parentId });
    },
    addFoodReplace(food) {
      this.$emit('add-replace', { id: food.foodId, parentId: food.id });
    },
    showPopupDelete(food) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn xóa ${food.foodName} không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.deleteFood(food);
          }
        }.bind(this),
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.div-blank {
  width: 48px;
  height: 48px;
}
</style>
